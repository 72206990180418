
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface passwordData {
  old_password: string;
  password: string;
  password_confirm: string;
}

@Component({
  components: {
    FormValidator
  }
})
export default class CambiarPassword extends Mixins(FormValidator) {
  private ruta = "";
  protected formData: passwordData = {
    old_password: "",
    password: "",
    password_confirm: ""
  };

  private credentials_found = true;
  private mounted() {
    window.scroll(0, 0);
    if (this.$router.currentRoute.name == "cambiarpassword")
      this.ruta = "Restablecer Contraseña";
  }

  private updatePassword() {
    this.$q.loading.show();
    this.$axios
      .patch(
        "personas/contrasena/" + this.$store.state.user.user_id,
        {
          current_password: this.formData.old_password,
          new_password_1: this.formData.password,
          new_password_2: this.formData.password_confirm
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          }
        }
      )
      .then(response => {
        this.$q.notify({
          message: "Se ha actualizado correctamente su contraseña.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.$router.push({ name: "welcome" });
      })
      .catch(error => {
        if (error.response.data.error.message == "password_not_match") {
          this.$q.notify({
            message: "La contraseña actual ingresada no es correcta",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        } else if (
          error.response.data.error.message == "auth/too-many-requests"
        ) {
          this.$q.notify({
            message:
              "Ha realizado muchos intentos fallidos, intente nuevamente después.",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        } else {
          this.$q.notify({
            message: "Ha ocurrido un error, intentelo nuevamente.",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        }
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }
}
