
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import comunas from "@/helpers/comunas.json";
import countries from "@/helpers/countries.json";

interface registerData {
  district: any;
  street: string;
  street_number: string;
  hint: string;
}

@Component
export default class UpdateAddressForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;

  options_district: any = comunas;
  options_street: any = [];
  street_loading = false;
  street_search_empty = false;
  streets: any[] = [];
  comuna_municipalidad: any = {
    id: 0,
    id_region: 0,
    nombre: ""
  };

  protected formData: registerData = {
    district: "",
    street: "",
    street_number: "",
    hint: ""
  };

  private updateAddress(): void {
    this.$axios
      .patch(
        "direccions/" + this.$store.state.user.id_address,
        {
          id_comuna: Number(this.formData.district.value),
          calle: this.formData.street,
          numero: this.formData.street_number,
          aclaratoria: this.formData.hint
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          }
        }
      )
      .then(response => {
        this.$emit("success");
        this.$store.dispatch("updateAddress", response.data);
      })
      .catch(error => {
        this.$emit("error", "address", error.response.data.error.message);
      });
  }

  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_municipalidad.nombre) {
      this.formData.street = "";
    }
  }

  private async filterStreet(val: string, update: any, abort: any) {
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_street = this.streets;
      });
      return;
    }
    update(() => {
      const value = val.toLowerCase();
      this.options_street = (this.streets as any).filter((street: any) => {
        return street.nombre.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  private abortFilterFn() {}

  private filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return (
          comuna.label.toLowerCase().indexOf(value) > -1 ||
          comuna.label === this.formData.district.label
        );
      });
    });
  }

  private streetDistrictData() {
    this.$axios
      .get("calles", {
        params: {
          filter: {
            where: { estado: 1 },
            fields: ["id", "id_comuna", "nombre", "estado"],
            order: "nombre ASC"
          }
        }
      })
      .then(response => {
        this.streets = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });

    this.$axios
      .get("comunas/default")
      .then(response => {
        this.comuna_municipalidad = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private mounted() {
    window.scrollTo(0, 0);
    this.formData.district = comunas.find(
      district => district.label === this.$store.state.user.adress.district
    );
    this.formData.street = this.$store.state.user.adress.street;
    this.formData.street_number = this.$store.state.user.adress.number;
    this.formData.hint = this.$store.state.user.adress.hint;
    this.streetDistrictData();
  }
}
