
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface passwordData {
  old_password: string;
  password: string;
  password_confirm: string;
}

@Component
export default class UpdatePasswordForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  protected formData: passwordData = {
    old_password: "",
    password: "",
    password_confirm: ""
  };

  private credentials_found = true;

  private mounted() {
    window.scrollTo(0, 0);
  }

  private updatePassword(): boolean {
    this.$axios
      .patch(
        "personas/contrasena/" + this.$store.state.user.user_id,
        {
          current_password: this.formData.old_password,
          new_password_1: this.formData.password,
          new_password_2: this.formData.password_confirm
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          }
        }
      )
      .then(response => {
        this.$emit("success");
      })
      .catch(error => {
        this.$emit("error", "password", error.response.data.error.message);
      });
    return true;
  }
}
