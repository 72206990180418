
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import countries from "@/helpers/countries.json";
import comunas from "@/helpers/comunas.json";
import FormValidator from "@/mixins/FormValidator";
import DateHelpers from "@/mixins/DateHelpers";
import LabelHelpers from "@/mixins/LabelHelpers";
import UploadCV from "@/components/forms/UploadCV.vue";

interface updateData {
  name: string;
  father_last_name: string;
  mother_last_name: string;
  nationality: any;
  email: string;
  phone: string;
  district: any;
  street: string;
  street_number: string;
  hint: string;
  date_birth: string;
  genre: string | number;
}

@Component({
  components: {
    FormValidator,
    UploadCV
  }
})
export default class Configuracion extends Mixins(
  FormValidator,
  DateHelpers,
  LabelHelpers
) {
  private ruta = "";
  private edit: Boolean = false;
  private text = "";
  private nacionalidad: any = "";
  private options_nationality: any = countries;
  private options_district: any = comunas;
  private options_street: any = [];
  private street_loading = false;
  private street_search_empty = false;
  private streets: any[] = [];

  private fechaHoy: any = new Date();
  private formattedFecha: string | null = "";

  private id_comuna = process.env.VUE_APP_ID_COMUNA;

  private comuna_municipalidad: any = {
    id: 0,
    id_region: 0,
    nombre: ""
  };

  protected formData: updateData = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    nationality: {
      value: 47,
      code: "CL",
      country_en: "Chile",
      country_es: "Chile",
      label: "Chilena"
    },
    email: "",
    phone: "",
    district: "",
    street: "",
    street_number: "",
    hint: "",
    date_birth: "",
    genre: ""
  };

  options_genre = [
    { label: "Femenino", value: "1" },
    { label: "Masculino", value: "2" },
    { label: "Otro", value: "3" },
    { label: "Prefiero no decirlo", value: "4" },
    { label: "Sin información", value: "5" }
  ];

  my_locale = {
    days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    firstDayOfWeek: 7
  };

  @Watch("formData.phone")
  private watchPhone(newValue: any) {
    let formated = newValue.replace(" ", "");
    formated = formated.replace(" ", "");
    formated = formated.replace(".", "");
    formated = formated.replace("-", "");
    formated = formated.replace("+", "");
    formated = formated.replace(",", "");
    this.formData.phone = formated;
  }

  private limitSince(date: any) {
    return Date.parse(date as any) <= Date.parse(this.fechaHoy as any);
  }

  private updateInfo(): void {
    if (this.formData.name == "") {
      this.$q.notify({
        message: "Por favor, ingrese su nombre.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }

    if (this.formData.father_last_name == "") {
      this.$q.notify({
        message: "Por favor, ingrese su apellido paterno.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }

    if (this.formData.mother_last_name == "") {
      this.$q.notify({
        message: "Por favor, ingrese su apellido materno.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }

    if (this.formData.email == "") {
      this.$q.notify({
        message: "Por favor, ingrese su correo electrónico.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    } else if (!this.validEmail(this.formData.email)) {
      this.$q.notify({
        message: "Por favor, ingrese un correo electrónico valido.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }

    if (this.formData.phone == "") {
      this.$q.notify({
        message: "Por favor, ingrese su teléfono.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    } else if (
      this.formData.phone.length < 9 ||
      this.formData.phone.length > 9
    ) {
      this.$q.notify({
        message: "Por favor, ingrese un número de teléfono de 9 dígitos",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }

    if (this.formData.street == "") {
      this.$q.notify({
        message: "Por favor, ingrese la calle.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }

    if (this.formData.street_number == "") {
      this.$q.notify({
        message: "Por favor, ingrese el número.",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    }

    if (this.formattedFecha != "" && this.formattedFecha != null) {
      const { formattedFecha } = this;
      if (!/^\d{2}-\d{2}-\d{4}$/.test(formattedFecha)) {
        this.$q.notify({
          message:
            "Fecha incompleta. Por favor, ingrese una fecha en el formato dd-mm-aaaa",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        return;
      } else if (!this.checkDate(formattedFecha)) {
        this.$q.notify({
          message: "Fecha inválida. Por favor, escriba una fecha válida",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        return;
      } else if (
        Date.parse(this.fechaHoy as any) <
        Date.parse(this.birthDate(formattedFecha))
      ) {
        this.$q.notify({
          message: "La fecha de nacimiento debe ser menor a la fecha actual",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        return;
      }
    } else {
      this.formattedFecha = null;
    }

    this.editDataUser();
    this.edit = false;
  }

  private editDataUser() {
    this.$q.loading.show();
    this.$axios
      .patch(
        "personas/front/editar",
        {
          nombre: this.formData.name,
          apellido_paterno: this.formData.father_last_name,
          apellido_materno: this.formData.mother_last_name,
          email: this.formData.email,
          telefono: this.formData.phone,
          id_pais: this.formData.nationality.value,
          fecha_nacimiento:
            this.formattedFecha == "" || this.formattedFecha == null
              ? "1970-01-01 00:00:00"
              : this.birthDate(this.formattedFecha) + " 00:00:00",
          genero: Number(this.formData.genre),
          //borrar una vez que se arregle el endpoint
          // kkk
          tipo: 1
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          }
        }
      )
      .then(response => {
        if (!response.data.fecha_nacimiento) {
          response.data.fecha_nacimiento = "";
          this.formattedFecha = "";
        }
        this.$store.dispatch("updateUser", response.data);
        this.editDireccionUser();
      })
      .catch(error => {
        this.nacionalidad = countries.find(
          country => country.value === this.$store.state.user.country
        );
        this.formData.name = this.$store.state.user.name;
        this.formData.father_last_name = this.$store.state.user.father_last_name;
        this.formData.mother_last_name = this.$store.state.user.mother_last_name;
        this.formData.email = this.$store.state.user.email;
        this.formData.phone = this.$store.state.user.phone;
        this.formattedFecha = this.$store.state.user.date_birth;
        this.formData.genre = this.$store.state.user.genre;
        let message = "Ha ocurrido un error";
        if (error.response.data.error.message == "auth/email-already-exists") {
          message = "El correo ya ha sido utilizado en el sistema";
        } else if (
          error.response.data.error.message == "duplicate_email_error"
        ) {
          message = "El correo ya ha sido utilizado en el sistema";
        } else if (error.response.data.error.message == "duplicate_email") {
          message = "El correo ya ha sido utilizado en el sistema";
        } else {
          message = "Ha ocurrido un problema. Intentelo más tarde";
        }
        this.$q.notify({
          message: message,
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private editDireccionUser() {
    this.$q.loading.show();
    this.$axios
      .patch(
        "direccions/front/editar",
        {
          id_comuna: Number(this.formData.district.value),
          calle: this.formData.street,
          numero: this.formData.street_number as string,
          aclaratoria: this.formData.hint
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          }
        }
      )
      .then(response => {
        this.$store.dispatch("updateAddress", response.data);
        this.$q.notify({
          message: "Se han actualizado sus datos.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .catch(error => {
        this.formData.district = comunas.find(
          district => district.label === this.$store.state.user.adress.district
        );
        this.formData.street = this.$store.state.user.adress.street;
        this.formData.street_number = this.$store.state.user.adress.number;
        this.formData.hint = this.$store.state.user.adress.hint;
        this.$q.notify({
          message: "Ha ocurrido un problema. Intentelo más tarde.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private guardar() {
    this.edit = false;
  }

  private filterNationality(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_nationality = countries;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_nationality = (countries as any).filter((country: any) => {
        let filtered =
          country.label.toLowerCase().indexOf(value) > -1 ||
          country.label === this.formData.nationality.label;
        return filtered;
      });
    });
  }

  private filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return (
          comuna.label.toLowerCase().indexOf(value) > -1 ||
          comuna.label === this.formData.district.label
        );
      });
    });
  }

  private async filterStreet(val: string, update: any, abort: any) {
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_street = this.streets;
      });
      return;
    }
    update(() => {
      const value = val.toLowerCase();
      this.options_street = (this.streets as any).filter((street: any) => {
        return street.nombre.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_municipalidad.nombre) {
      this.formData.street = "";
    }
  }

  private streetDistrictData() {
    this.$axios
      .get("calles", {
        params: {
          filter: {
            where: { estado: 1 },
            fields: ["id", "id_comuna", "nombre", "estado"],
            order: "nombre ASC"
          }
        }
      })
      .then(response => {
        this.streets = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });

    this.$axios
      .get("comunas/default")
      .then(response => {
        this.comuna_municipalidad = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private rutFormat(rut: string) {
    if (rut.replace("-", "").replace(/[.-]/g, "").length <= 7) {
      return rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      return rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }

  private validEmail(email: any) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  private mounted() {
    if (this.$router.currentRoute.name == "configuracion")
      this.ruta = "Datos Personales";
    this.nacionalidad = countries.find(
      country => country.value === this.$store.state.user.country
    );

    this.formData.name = this.$store.state.user.name;
    this.formData.father_last_name = this.$store.state.user.father_last_name;
    this.formData.mother_last_name = this.$store.state.user.mother_last_name;
    this.formData.email = this.$store.state.user.email;
    this.formData.phone = this.$store.state.user.phone;
    this.formData.district = comunas.find(
      district => district.label === this.$store.state.user.adress.district
    );
    this.formData.street = this.$store.state.user.adress.street;
    this.formData.street_number = this.$store.state.user.adress.number;
    this.formData.hint = this.$store.state.user.adress.hint;
    this.formData.genre = this.$store.state.user.genre.toString();
    if (this.$store.state.user.date_birth != null) {
      this.formattedFecha = this.correctDate(this.$store.state.user.date_birth);
    }

    this.streetDistrictData();
  }
}
