
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import comunas from "@/helpers/comunas.json";
import countries from "@/helpers/countries.json";

interface updateData {
  name: string;
  father_last_name: string;
  mother_last_name: string;
  nationality: any;
  email: string;
  phone: string;
  genre: string;
  date_birth: string;
}

@Component
export default class UpdateAccountForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];

  options_district: any = comunas;
  options_nationality: any = countries;
  options_street: any = [];
  street_loading = false;
  street_search_empty = false;

  protected formData: updateData = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    nationality: {
      value: 47,
      code: "CL",
      country_en: "Chile",
      country_es: "Chile",
      label: "Chilena"
    },
    email: "",
    phone: "",
    genre: "",
    date_birth: ""
  };

  @Watch("formData.phone")
  private watchPhone(newValue: any) {
    let formated = newValue.replace(" ", "");
    formated = formated.replace(" ", "");
    formated = formated.replace(".", "");
    formated = formated.replace("-", "");
    formated = formated.replace("+", "");
    formated = formated.replace(",", "");
    this.formData.phone = formated;
  }

  private updateInfo(): void {
    this.$axios
      .patch(
        "personas/" + this.$store.state.user.user_id,
        {
          nombre: this.formData.name,
          apellido_paterno: this.formData.father_last_name,
          apellido_materno: this.formData.mother_last_name,
          email: this.formData.email,
          telefono: this.formData.phone,
          id_pais: this.formData.nationality.value,
          //borrar una vez que se arregle el endpoint
          tipo: 1
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          }
        }
      )
      .then(response => {
        this.$emit("success");
        this.$store.dispatch("updateUser", response.data);
      })
      .catch(error => {
        this.$emit("error");
      });
  }

  private abortFilterFn() {}

  private filterNationality(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_nationality = countries;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_nationality = (countries as any).filter((country: any) => {
        let filtered =
          country.label.toLowerCase().indexOf(value) > -1 ||
          country.label === this.formData.nationality.label;
        return filtered;
      });
    });
  }

  mounted() {
    window.scrollTo(0, 0);
    this.formData.name = this.$store.state.user.name;
    this.formData.father_last_name = this.$store.state.user.father_last_name;
    this.formData.mother_last_name = this.$store.state.user.mother_last_name;
    this.formData.nationality = countries.find(
      country => country.value === this.$store.state.user.country
    );
    this.formData.email = this.$store.state.user.email;
    this.formData.phone = this.$store.state.user.phone;
  }
}
